<template>
  <v-dialog v-model="dialog" persistent max-width="400px">
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12">
            <p>Confirmar o fechamento do inventário?</p>
          </v-col>
          <v-col cols="12">
            <v-switch class="mt-0" v-model="form.block_sell_stock_negative" hide-details
              label="Bloquear venda abaixo do estoque?" :true-value="1" :false-value="0"></v-switch>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="dialog = false" text class="text-capitalize" color="secondary">
          Cancelar
        </v-btn>
        <v-btn @click="handleSave()" class="text-capitalize" color="primary">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      form: {
        block_sell_stock_negative: 0,
      }
    };
  },
  methods: {
    open(id) {
      this.form.id = id;
      this.dialog = true;
    },

    handleSave() {
      this.$http
        .update("stock/close-stock-audit", this.form.id, this.form)
        .then((response) => {
          this.$emit("store");
        })
        .catch((error) => {
          this.$toast.error(error.message);
          this.dialog = false;
        });
      this.dialog = false;
    },
  },
};
</script>
<style></style>