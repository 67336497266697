var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('app-text-search-field',{on:{"input":function($event){return _vm.handleSearchInput($event)}}})],1),_c('v-col',{staticClass:"text-right d-md-block",attrs:{"md":"8"}},[(_vm.$acl.can('stockAuditCreateEdit'))?_c('v-btn',{attrs:{"bottom":"","color":"primary"},on:{"click":function($event){return _vm.handleNewStockAudit()}}},[_vm._v(" Novo ")]):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('CompanySelect',{ref:"CompanySelect",attrs:{"item-value":function (val) { return val.id; },"filter":function (company) { return company.status == 1; },"clearable":""},on:{"input":function($event){return _vm.select()}},model:{value:(_vm.searchParams.company_id),callback:function ($$v) {_vm.$set(_vm.searchParams, "company_id", $$v)},expression:"searchParams.company_id"}})],1),_c('v-col',[_c('v-chip-group',{attrs:{"mandatory":""},on:{"change":function($event){return _vm.select()}},model:{value:(_vm.searchParams.status),callback:function ($$v) {_vm.$set(_vm.searchParams, "status", $$v)},expression:"searchParams.status"}},[_c('v-chip',{attrs:{"small":"","color":"primary","filter":"","value":"active"}},[_vm._v(" Aberto ")]),_c('v-chip',{attrs:{"small":"","color":"error","filter":"","value":"closed"}},[_vm._v("Fechado")])],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.stock_audits.data,"items-per-page":-1,"hide-default-footer":"","disable-sort":"","dense":""},on:{"click:row":function($event){return _vm.openStockAuditProducts($event.id)}},scopedSlots:_vm._u([{key:"item.started_at",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.$format.dateTimeBr(item.started_at))+" ")]}},{key:"item.finished_at",fn:function(ref){
var item = ref.item;
return [(item.status == 'closed')?[_vm._v(" "+_vm._s(_vm.$format.dateTimeBr(item.finished_at))+" ")]:[_vm._v(" -- ")]]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","color":_vm.statusData[item.status].color}},[_vm._v(_vm._s(_vm.statusData[item.status].text))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[(_vm.$acl.can('stockAuditCreateEdit'))?_c('v-list-item',{attrs:{"disabled":item.status == 'closed'},on:{"click":function($event){$event.stopPropagation();return _vm.handleNewStockAudit(item.id)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Editar")])],1)],1):_vm._e(),(_vm.$acl.can('stockAuditCreateEdit'))?_c('v-list-item',{attrs:{"disabled":item.status == 'closed'},on:{"click":function($event){$event.stopPropagation();return _vm.handleStopStockAudit(item.id)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-lock-open")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Fechar")])],1)],1):_vm._e(),(_vm.$acl.can('stockAuditCreateEdit'))?_c('v-list-item',{attrs:{"disabled":item.status == 'closed'},on:{"click":function($event){$event.stopPropagation();return _vm.handleDeleteStockAudit(item.id)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Apagar")])],1)],1):_vm._e()],1)],1)]}}],null,true)}),_c('app-pagination',{attrs:{"data":_vm.stock_audits},on:{"click":function($event){return _vm.pagination($event)}}})],1)],1),_c('StoreStockAuditForm',{ref:"StoreStockAuditForm",on:{"store":function($event){return _vm.select()}}}),_c('StoreStockAuditDialog',{ref:"StoreStockAuditDialog",on:{"store":function($event){return _vm.select()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }